<template>
  <div id="menubody">
    <div class="showbody">
      <ul class="showul">
        <span v-if="totalnum!=0?false:true"
        class="showulspan"
        >{{$t('m.reservation.No_data_found')}}!!</span>
        <li class="showli"
         v-for="(item,index) in yuhsouList" :key="index">
          <div class="yscont"
          @click="toDetails(item)">
            <div class="card_img">
              <img :src="item.productPic">
              <span class="overtime" v-if="item.status==0">
                {{$t('m.auction.start_time')}}：<i>{{item.startTime}}</i>
              </span>
              <span class="overtime" v-if="item.status==1">
                {{$t('m.auction.end_time')}}：<i>{{item.endTime}}</i>
              </span>
              <!-- <span class="overtime">
                结束时间：<i>{{item.endTime}}</i>
              </span> -->
            </div>
            <p class="p1 ptitle">
              {{item.name}}
            </p>
            <p class="p1 ptitle">
              {{item.productName}}
            </p>
            <p class="p1">
              <span class="p1span">
                  <i class="zititi c1">￥</i>
                  <NologindataVue :price='item.smsAuctionSku.realPrice' />
                  <i class="zititi">/{{item.unit}}</i>
              </span>
              <button class="nextbtn">
              {{ item.numberOfShots || 0 }}{{$t('m.auction.offers')}}</button>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div class="showfooter" v-if="totalnum<=pagesize?false:true">
      <el-pagination
        v-model:currentPage="currentPage"
        :page-size="pagesize"
        layout="prev, pager, next, jumper"
        :total="totalnum"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import http from '../../api/http'
import { ref } from 'vue-demi'
import { ElMessage } from 'element-plus'
import emitter from '../../tools/bus'
import NologindataVue from '../../components/Nologindata.vue'
// import { useRouter } from 'vue-router'

// 查询竞拍列表
const AuctionData = (data) => {
  return http.get('/mall-buyer/auction/getAuctionList', data, true)
}
export default {
  name: 'AuctionBodyList',
  data () {
    return {
      status: null, // 0：待活动开始，1：活动中，2：抢拍结束
      type: 0, // 类型，0：英式，1：荷兰式，2：固定
      // 存放总数据个数
      totalnum: ref(100),
      // 存放一页显示个数
      pagesize: ref(16),
      // 默认展示页
      currentPage: ref(1),
      yuhsouList: ref({})
    }
  },
  components: {
    NologindataVue
  },
  methods: {
    handleCurrentChange (val) {
      // console.log(`current page: ${val}`)
      this.currentPage = val
      this.initData()
    },
    async initData () { // 查询数据 默认显示英式(向上)
      const loading = this.$loading({
        lock: true,
        text: '加载中..'
      })
      const data = {
        type: this.type,
        section: '',
        pageSize: this.pagesize, // 页面个数
        pageNum: this.currentPage, // 第几页
        status: this.status,
        // specialSubjectCityCode:this.cityCode,
        specialSubjectId: ''
      }
      const timer = setTimeout(() => {
        loading.close()
        clearTimeout(timer)
      }, 30000)
      // timer()
      const res = await AuctionData(data)
      if (res.data.code === 200) {
        this.yuhsouList = res.data.data.records
        this.totalnum = res.data.data.total // 个数
        console.log(res.data.data)
        clearTimeout(timer)
        loading.close()
        return ''
      } else {
        clearTimeout(timer)
        loading.close()
        ElMessage.warning({
          message: '查询出错了！！'
        })
      }
    },
    toDetails (data) {
      console.log(data)
      // this.$router.push('/auction/englishauction')
      if (data.type === 1) {
        // this.$router.push('/robDetail')
        const routeData = this.$router.resolve({
          path: '/auction/englishauction', // 荷兰式
          query: {
            id: data.id,
            type: data.type
          }
        })
        window.open(routeData.href, '_blank')
      } else {
        const routeData = this.$router.resolve({
          path: '/auction/englishauction', // 英式 暂时都是相同页面
          query: {
            id: data.id,
            type: data.type
          }
        })
        window.open(routeData.href, '_blank')
      }
    }
  },
  mounted () {
    // console.log(data)
    this.initData()
    emitter.on('moshi', (data) => {
      console.log(data)
      let num = 0
      switch (data) {
        case '英式竞拍':
          num = 0
          break
        case '精品英式竞拍':
          num = 0
          break
        case '荷兰式竞拍':
          num = 1
          break
        case '固定':
          num = 2
          break
      }
      this.type = num
      this.currentPage = 0
      this.initData()
    })
    // this.yuhsouList = data.list
    // console.log(this.yuhsouList)
  },
  setup (props, ctx) {
    const handleSizeChange = (val) => {
      console.log(`${val} items per page`)
    }
    return {
      handleSizeChange
    }
  }
}
</script>

<style lang="scss">
@import '../../style/viriables.scss';
#menubody{
  margin-top: 10px;
  width: 100%;
  flex: 1;
  display: flex;
  flex-flow: column;
  .showbody{
    width: 100%;
    flex: 1;
    .showul{
      .showulspan{
        display: inline-block;
        width: 100%;
        color: #AC703B;
        line-height: 100px;
        font-size: 30px;
        text-align: center;
      }
      .showli{
        float: left;
        width: 300px;
        box-sizing: border-box;
        padding: 20px;
        background: $divbgColor;
        border-right: 1px solid $bgColor;
        border-bottom: 1px solid $bgColor;
        .yscont{
          position: relative;
          cursor: pointer;
          p{
            line-height: 35px;
          }
          .card_img{
            position: relative;
            width: 260px;
            height: 260px;
            img{
              width: 260px;
              height: 260px;
            }
            .overtime{
              position: absolute;
              bottom: 0;
              width: 100%;
              display: block;
              height: 40px;
              text-align: center;
              line-height: 40px;
              background: #280E01;
              opacity: 0.9;
              font-size: 14px;
              font-weight: 400;
              color: #C6824E;
            }
          }
          .p1{
            color: #333333;
            font-size: 14px;
            // margin: ;
            font-family: Microsoft YaHei;
            font-weight: 400;
            width: 260px;
            .p1span{
              display: inline-block;
              font-weight: bold;
              text-align: right;
              color: #c88756;
              font-size: 20px;
              .c1{
                color: #c88756;
                font-weight: 400;
              }
            }
            .nextbtn{
              float: right;
              width: 100px;
              height: 40px;
              background: linear-gradient(0deg, #280E01 0%, #673000 100%);
              border-radius: 4px;
              color: #FFFFFF;
            }
          }
          .ptitle{
            line-height: 21px;
            margin: 10px 0 5px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          .zititi{
            color: #999999;
            font-size: 14px;
            .p2{
              color: #333333;
            }
          }
        }
      }
    }
  }
  .showfooter{
    height: 100px;
    // background: red;
    margin: 0 auto;
    display: flex;
    align-items: center;
    .el-pagination{
      // line-height: 100px;
      .el-pager{
        margin: 0 20px;
        .number,.btn-quicknext{
          margin: 0 5px;
          width: 40px;
          height: 40px;
          border: 1px solid transparent;
          line-height: 40px;
        }
        .active{
          background: #ffffff;
          color: #C6824E;
          border-color: #C6824E;
        }
        .number:hover{
          color: #C6824E;
        }
        .el-icon{
          height: 40px;
          line-height: 40px;
        }
      }
      .btn-prev,.btn-next,.el-pagination__jump{
        height: 40px;
        line-height: 40px;
        .el-input{
          height: 40px;
          .el-input__inner{
            height: 40px;
          }
        }
      }
    }
  }
}
</style>
