<template>
  <div class="aucion_page">
    <div class="header_main">
      <header-base :btn="{title:$t('m.auction.My_auction'), to:'/auction/myauction' }"></header-base>
    </div>
    <div class="aucion_content">
      <div class="banner">
        <div class="ban_left">
          <div><p>{{$t('m.auction.Bidding_process')}}</p></div>
          <img src="https://jingniu-file.oss-cn-zhangjiakou.aliyuncs.com/web/flowimg/jingpaiImg.png" alt="">
        </div>
        <div class="ban_right" v-loading="loading">
          <el-carousel height="420px"
          ref="carousel"
          :interval="4000" arrow="hover">
            <el-carousel-item v-for="item in bannerImg" :key="item">
              <img class="right_img" :src="item.pic" alt="">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="content_body">
        <auction-body :pattern="thisPattern"></auction-body>
      </div>
    </div>
    <div class="divfooter">
       <Footer/>
    </div>
  </div>
</template>

<script>
import HeaderBase from '../../components/HeaderBase.vue'
import AuctionBody from './AuctionBody.vue'
import Footer from '../index/Footer.vue'
import { homeAdvertiseList } from '../../api/api'

import { useRoute } from 'vue-router'
import { onMounted, ref } from 'vue-demi'
import { ElMessage } from 'element-plus'

export default {
  components: { HeaderBase, Footer, AuctionBody },
  name: 'AuctionMain',
  data () {
    return {
      bannerImg: ref([]),
      loading: ref(true)
    }
  },
  methods: {
    async getPic () {
      const data = {
        types: 3
      }
      const res = await homeAdvertiseList(data)
      console.log(res)
      if (res.data.code === 200) {
        this.bannerImg = res.data.data
        this.loading = false
      } else {
        ElMessage.warning({
          message: res.data.msg
        })
      }
    }
  },
  mounted () {
    this.getPic()
    window.document.documentElement.scrollTop = 0 // 渲染 详情组件时让 回到顶部
    // console.log(this.$route.params.pattern)
  },
  setup () {
    const route = useRoute()
    // console.log(route.params.pattern)
    const carousel = ref(null)
    onMounted(() => {
      setTimeout(() => {
        carousel.value.setActiveItem(0)
      }, 500)
    })
    // const router = useRouter()
    return {
      carousel,
      thisPattern: ref(route.params.pattern ? route.params.pattern : '英式竞拍')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
.aucion_page{
  background: $bgColor;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  width: 100%;
  // height: 500px;
  .aucion_content{
    width: 1200px;
    margin: 0 auto;
    .banner{
      margin-top: 10px;
      height: 420px;
      width: 1200px;
      display: flex;
      .ban_left{
        width: 260px;
        background: #322D2B;
        div{
          background: url("../../static/imges/yushouLC01.png") no-repeat;
          width: 260px;
          height: 85px;
          p{
            line-height: 75px;
            font-size: 18px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #322D2B;
            text-align: center;
          }
        }
        img{
          // margin-top: 20px;
          padding: 20px 30px;
          padding-top: 0;
          height: 310px;
          width: 200px;
        }
      }
      .ban_right{
        flex: 1;
        // width: 940px;
        .right_img{
          width: 100%;
          height: 100%;
        }
      }
    }
    .content_body{
      margin-top: 10px;
    }
  }
  .divfooter{
    background: #280E01;
  }
}
</style>
