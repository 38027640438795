<template>
<div class="content">
  <div class="menutitle">
    <div class="title_div">
      <span class="navspan">{{$t('m.auction.Auction_mode')}}:</span>
      <ul class="huotime">
        <li v-for="(i,k) in moshi" :key="k"
        :class="actpattern==k?'isact':''"
        @click="isActive(i,k)">
          {{i}}
        </li>
      </ul>
    </div>
    <div class="title_nav">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
        v-for="(i,k) in commodityName" :key="k"
        :label="i.label"
        :name="i.name"
        >
          <component :is="activeName"
          @auctionlistData="()=>{auctionListShow}">
          </component>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <div class="auction_show">
    <auction-body-list :moshi="moshi[actpattern]"></auction-body-list>
  </div>
</div>
</template>

<script>
import emitter from '../../tools/bus'
import AuctionBodyList from './AuctionBodyList.vue'

export default {
  components: { AuctionBodyList },
  name: 'AuctionBody',
  data () {
    return {
      moshi: [this.$t('m.auction.English_Auction')],
      actpattern: '',
      commodityName: [
        { label: this.$t('m.auction.all_products'), name: 'allauction' }
      ], // 竞拍状态标题内容
      activeName: 'allauction', // 当前选择的竞拍项
      auctionData: []
    }
  },
  methods: {
    isActive (i, k) {
      // if (k === this.$props.pattern) {
      //   this.actpattern = this.$props.pattern
      // }
      this.actpattern = k
      emitter.emit('moshi', i)
    },
    // 点击切换的函数
    handleClick (tab, event) {
      // console.log(tab.props)
      this.auctionListShow(tab.props.name)
    },
    auctionListShow (name) {
      // console.log(name)
      emitter.emit('auctionDataTo', { name: name, list: this.auctionData })
    }
  },
  mounted () {
    this.auctionListShow('allauction')
    this.actpattern = this.$props.pattern
  },
  props: ['pattern'],
  setup (props, ctx) {
    console.log(props.pattern)
    return {}
  }
}
</script>

<style lang="scss">
.content{
  .menutitle{
    background: #FFFFFF;
    .title_div{
      padding: 0 20px;
      display: flex;
      height: 70px;
      justify-content: right;
      align-items: center;
      border-bottom: 1px solid #F2EFED;
      .navspan{
        font-size: 20px;
        margin-right: 30px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #333333;
      }
      .huotime{
        flex: 1;
        display: flex;
        align-items: center;
        height: 100%;
        li{
          padding:0 20px;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          cursor: pointer;
          text-align: center;
          height: 40px;
          line-height: 40px;
        }
        .isact{
          background: #C6824E;
          color: #FFFFFF;
          border-radius: 4px;
        }
      }
    }
    .title_nav{
      height: 50px;
      padding: 0 20px;
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      .el-tabs{
        .el-tabs__header{
          margin: 0;
          .el-tabs__nav-wrap{
            .el-tabs__nav-scroll{
              .el-tabs__nav{
                .el-tabs__item{
                  height: 50px;
                  font-size: 14px;
                }
                .el-tabs__active-bar{
                  background: #C6824E;
                }
                .is-active{
                  color: #C6824E;
                }
                .el-tabs__item:hover{
                  color: #c49979;
                }
              }
            }
          }
          .el-tabs__nav-wrap::after{
            height: 0;
          }
        }
      }
    }
  }
}

</style>
